import * as React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export default function Error() {
  return (
    <div className="max-w-3xl mx-auto py-8 text-center">
      {/** TODO: Add text logo */}
      <p className="text-2xl font-bold mb-4">Whoops! Something went wrong...</p>
      <p>For support contact us</p>
      <p className="font-bold">partnerships@ooofer.com</p>
      <AnchorLink to="/" title="ooofer" className="btn-primary mt-6">
        Back to home page
      </AnchorLink>
    </div>
  );
}
